import { IconButton, Tooltip } from "@mui/material";
import "./CircleIconButton.scss";
import { CircleIconButtonProps } from "./CircleIconButtonProps";

const CircleIconButton = ({
  children,
  onClick,
  showBorder,
  active,
  selected,
  solid,
  done,
  tooltipTitle,
  ...rest
}: CircleIconButtonProps) => {
  const Button = (
    <IconButton
      onClick={onClick}
      {...rest}
      className={`circleIcon ${
        showBorder ? "circleIconBordered" : ""
      } ${active ? "circleIconActive" : ""} ${
        selected ? "circleIconSelected" : ""
      } ${solid ? "circleIconSolid" : ""} ${done ? "circleIconDone" : ""}`}
    >
      {children}
    </IconButton>
  );
  return tooltipTitle && !rest.disabled ? (
    <Tooltip title={tooltipTitle}>{Button}</Tooltip>
  ) : (
    Button
  );
};

export default CircleIconButton;
