"use client";

import {
  CircleIconButton,
  ImageWrapper,
} from "@anthology/shared/src/components";
import { slinkyIconUrl } from "@anthology/shared/src/constants/urls";
import { useCssBreakpoint } from "@anthology/shared/src/hooks";
import { Button, Stack } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import { BsEnvelope } from "react-icons/bs";

const contact = () => {
  window.open("https://absolutelabelservices.com/#contact", "_blank");
};

function Nav() {
  const isMobileLayout = useCssBreakpoint("sm");
  return (
    <AppBar
      position="static"
      sx={{
        color: "var(--mui-palette-background-card)",
        backgroundColor: "#fff",
      }}
    >
      <Container maxWidth="xl">
        <Stack
          gap={15}
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
          height={70}
        >
          <Stack direction={"row"} gap={2} alignItems={"center"}>
            <ImageWrapper src={slinkyIconUrl} height={40} />

            <Typography
              variant="h4"
              noWrap
              component="a"
              href="/"
              sx={{
                mr: 2,
                fontFamily: "monospace",
                fontWeight: 700,
                letterSpacing: ".3rem",
                color: "inherit",
                textDecoration: "none",
              }}
            >
              Slinky
            </Typography>
          </Stack>
          {isMobileLayout ? (
            <CircleIconButton color="primary" onClick={contact}>
              <BsEnvelope />
            </CircleIconButton>
          ) : (
            <Button
              size="medium"
              sx={{ p: 2 }}
              color="inherit"
              onClick={contact}
            >
              Contact Us
            </Button>
          )}
        </Stack>
      </Container>
    </AppBar>
  );
}
export default Nav;
